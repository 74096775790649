import { type SSO } from "./models/sso.server";
import { PasswordPolicyType } from "@aws-sdk/client-cognito-identity-provider";
import { useMatches, useNavigate } from "@remix-run/react";
import { Auth } from "aws-amplify";
import passwordValidator from "password-validator";
import React, { useMemo } from "react";
import logger from "~/logger.server";

const DEFAULT_REDIRECT = "/";

/**
 * This should be used any time the redirect path is user-provided
 * (Like the query string on our login/signup pages). This avoids
 * open-redirect vulnerabilities.
 * @param {string} to The redirect destination
 * @param {string} defaultRedirect The redirect to use if the to is unsafe.
 */
export function safeRedirect(
  to: FormDataEntryValue | string | null | undefined,
  defaultRedirect: string = DEFAULT_REDIRECT
) {
  if (!to || typeof to !== "string") {
    return defaultRedirect;
  }

  if (!to.startsWith("/") || to.startsWith("//")) {
    return defaultRedirect;
  }

  return to;
}

/**
 * This base hook is used in other hooks to quickly search for specific data
 * across all loader data using useMatches.
 * @param {string} id The route id
 * @returns {JSON|undefined} The router data or undefined if not found
 */
export function useMatchesData(
  id: string
): Record<string, unknown> | undefined {
  const matchingRoutes = useMatches();
  const route = useMemo(
    () => matchingRoutes.find((route) => route.id === id),
    [matchingRoutes, id]
  );
  return route?.data;
}

export function validateEmail(email: unknown): email is string {
  return typeof email === "string" && email.length > 3 && email.includes("@");
}

export function validatePassword(
  password: string,
  policy?: PasswordPolicyType
) {
  const schema = new passwordValidator();

  if (policy) {
    schema
      .is()
      .min(policy.MinimumLength || 0)
      .is()
      .max(100);

    if (policy.RequireLowercase) {
      schema.has().lowercase();
    }

    if (policy.RequireUppercase) {
      schema.has().uppercase();
    }

    if (policy.RequireNumbers) {
      schema.has().digits();
    }

    if (policy.RequireSymbols) {
      schema.has().symbols();
    }

    return schema.validate(password);
  }

  schema
    .is()
    .min(8)
    .is()
    .max(100)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits()
    .has()
    .symbols();

  return schema.validate(password);
}

export type Configuration = any;
export type Authenticator = {
  user: any;
};
export default function useAuthenticator(sso: SSO): Authenticator {
  const [user, setUser] = React.useState();

  const navigate = useNavigate();

  async function callAuth(configuration: Configuration) {
    try {
      await Auth.configure(configuration);
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      setUser(user);
    } catch (error) {
      navigate("/login");
    }
  }

  React.useEffect(() => {
    callAuth(sso.pool.configuration);
  }, []);

  return { user };
}

export function loadConfigurationFromURL(request: Request) {
  const url = new URL(request.url);
  const providers =
    url.searchParams
      .get("providers")
      ?.split(",")
      .map((_) => _.trim()) || [];

  const redirect = url.searchParams.get("redirect_uri");
  const disableAutoSubmit = Boolean(
    url.searchParams.get("disable_auto_submit")
  );
  const provider = url.searchParams.get("provider");

  return { providers, redirect, disableAutoSubmit, provider };
}

function urlSafeDecode(hex: string) {
  return hex
    .match(/.{2}/g)!
    .map((char) => String.fromCharCode(parseInt(char, 16)))
    .join("");
}

export function loadConfigurationFromState(request: Request) {
  const url = new URL(request.url);
  const error = url.searchParams.get("error");
  const error_description = url.searchParams.get("error_descrption");
  const state = url.searchParams.get("state") || "";

  if (error || error_description)
    logger.trace({ error, error_description }, "Tracing errors from state");

  const isCustomStateIncluded = /-/.test(state);
  if (isCustomStateIncluded) {
    const customState = JSON.parse(
      urlSafeDecode(state.split("-").splice(1).join("-"))
    );
    if (customState) logger.trace({ state: customState }, "Tracing state");
    return {
      error,
      error_description,
      providers: customState.sso || [],
      provider: customState.provider,
      redirect: customState.redirect,
    };
  }

  return { error, error_description, providers: [] };
}

export function createHeaders(
  headers: Headers,
  localStorage: Record<string, string>
) {
  for (let key in localStorage) {
    const value = localStorage[key];
    if (key !== "true" && key !== "")
      headers.append(
        "Set-Cookie",
        `${key}=${value};HttpOnly;Max-Age=86400; Path=/; Secure; SameSite=Lax;`
      );
  }

  return headers;
}

export async function mockWindowForAmplify(
  url: string,
  sessionStorage: Storage,
  callback: () => Promise<any>
) {
  //This is to trick amplify into thinking
  //it's in the browser
  //@ts-ignore: Unreachable code error
  //eslint-disable-next-line no-global-assign
  global.window = {
    //@ts-ignore: Unreachable code error
    location: {
      href: url,
    },
    //@ts-ignore: Unreachable code error
    document: {},
    sessionStorage,
  };

  try {
    return await callback();
  } finally {
    //@ts-ignore: Unreachable code error
    // eslint-disable-next-line no-global-assign
    global.window = undefined;
  }
}

type State =
  | {
      error: string | null;
      error_description: string | null;
      providers: any;
      provider: any;
      redirect: any;
    }
  | {
      error: string | null;
      error_description: string | null;
      providers: never[];
      provider?: undefined;
      redirect?: undefined;
    };
export function getProvidersFromState(
  state: State,
  cookies: Record<string, string>
) {
  let providers = state.providers;
  if (!providers || providers.length === 0) {
    const providersFromCookie = cookies.providers;
    if (providersFromCookie) {
      providers = providersFromCookie.split(",");
    }
  }
  //if provider not present in current request url
  //search it in cookies
  let provider = state.provider;
  if (!provider) {
    const providerFromCookie = cookies.provider;
    if (providerFromCookie) {
      provider = providerFromCookie;
    }
  }

  return { providers, provider };
}

export function getSearchParamsString(dictionary: Record<string, string>) {
  let searchParams = new URLSearchParams(dictionary);

  return `?${searchParams.toString()}`;
}

export function clearCookies(parsedCookies: Record<string, string>) {
  const headers = new Headers();
  const { providers: parsedCookiesProvider, ...parsedCookiesWithNoProvider } =
    parsedCookies;

  for (const cookieName in parsedCookiesWithNoProvider) {
    headers.append(
      "Set-Cookie",
      `${cookieName}=;HttpOnly;Max-Age=0;Expires=${new Date(
        1
      )}; Path=/; Secure; SameSite=Lax;`
    );
  }

  return headers;
}

export function clearCookie(headers: Headers, name: string) {
  headers.append(
    "Set-Cookie",
    `${name}=;HttpOnly;Max-Age=0;Expires=${new Date(
      1
    )}; Path=/; Secure; SameSite=Lax;`
  );

  return headers;
}
